.banner {
    background: #131c4c;
    color: white;
    padding: 40px;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .banner-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .banner-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .banner-button {
    background: white;
    color: #4f46e5;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .banner-button:hover {
    background: #e5e7eb;
  }
  .info-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 30px;
    margin-top: 20px;
    height: 60px;
  }
  
  .info-content {
    display: flex;
    align-items: center;
  }
  
  .info-icon {
    margin-right: 8px;
  }
  